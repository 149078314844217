import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from '../Home';
import NotFound from '../Error';
import './index.css';

class App extends Component {
    render() {
        return (
            <Router>
                <div className="App">
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/home" component={Home}/>
                        <Route render={(props) => <NotFound {...props}/>}/>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;
