import React from 'react';
import './index.css';

export class Home extends React.Component {
    render() {
        return (
            <div className="Home">
                <header className="Home-header">
                    <p>
                        {'Yes, this page is intentionally left blank!'}
                    </p>
                </header>
            </div>
        );
    }
}

export default Home;
