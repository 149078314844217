import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const propTypes = {
    children: PropTypes.any,
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
        };
    }

    componentDidCatch(error, info) {
        // TODO: Log the error to an error reporting service.
        // See: https://reactjs.org/docs/error-boundaries.html
    }

    render() {
        if (this.state.hasError) {
            // Render fallback UI.
            return (
                <div className="error-boundary">
                    <h1>
                        {'Sorry, something went wrong.'}
                    </h1>
                    <h3>
                        {'We are going to fix it up as soon as we can.'}
                    </h3>
                </div>
            );
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = propTypes;

export default ErrorBoundary;
