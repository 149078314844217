import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Header, Icon} from 'semantic-ui-react';
import './index.css';

const propTypes = {
    location: PropTypes.object,
    title: PropTypes.string,
};

const defaultProps = {
    location: {
        pathname: '',
    },
    title: '404 Not Found',
};

function NotFound({location, title}) {
    return (
        <div className="Error">
            <Grid textAlign="center" verticalAlign="top" className="Error-grid" padded>
                <Grid.Column className="Error-grid-column">
                    <Header as="h2" color="red" icon>
                        <Icon name="question circle"/>
                        {title}
                        <Header.Subheader>
                            {'The requested resource could not be found at '}<code>{location.pathname}</code>
                        </Header.Subheader>
                    </Header>
                </Grid.Column>
            </Grid>
        </div>
    );
}

NotFound.propTypes = propTypes;
NotFound.defaultProps = defaultProps;

export default NotFound;
